import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import Utils from "../../../../felles/Utils";
import SVGIconBin from "../../../../components/SVGIconBin";
import SVGIconEdit from "../../../../components/SVGIconEdit";
import MenuItemTarget from '../../../../models/MenuItemTarget';
import TaleCriteriaItem from '../../../../models/TaleCriteriaItem';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';
import CriteriaListTableHead from '../components/CriteriaListTableHead';
import CriteriaListTableToolbar from '../components/CriteriaListTableToolbar';


interface OwnProps {
  classes?: any, //place Holder
  size: any,
}

interface StateProps {
  onRequestSort?: (event: any, property: any) => void;
  menuItemTarget: MenuItemTarget;
  criteriaItemList: TaleCriteriaItem[];
  isFetchingCriteriaListItem: boolean;
  selectedCriteriaItemList: number[];
  excelImportState: number;
  userJWT: any,
  currentBreakpoint: string;
  className?: string;
  criteriaItemId: number;
  classes?: Partial<Record<"root" | "story" | "label" | "container" | "table" | "tableWrapper" | "tableCell" | "locallinkA" | "locallinkB" | "iconWrapper" | "spacer" | "actions" | "button", string>>;
  isLoadingComponent: boolean;
}
//
interface DispatchProps {
  setIsloading: (val) => void;
  setExcelImportState: (val) => void;
  excelPost: (val) => void;
  getTaleList: () => void;
  criteriaItemListExportToExcel: () => void;
  setCriteriaEditState: (val: number) => void;
  setCriteriaDeletionState: (val: number) => void;
  setCriteriaRegistrationState: (val: number) => void;
  setCriteriaItemSelectedList: (selected: number[]) => void;
  setSandwichButtonStateClicked: (val: boolean) => void;
  setCriteriaItemId: (val: number) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export function mapStateToProps(state: AppState): StateProps {
  return {
    menuItemTarget: state.menuItem.menuItemTarget,
    criteriaItemList: state.tallenesTale.criteriaItemList,
    isFetchingCriteriaListItem: state.tallenesTale.isFetchingCriteriaListItem,
    selectedCriteriaItemList: state.tallenesTale.selectedCriteriaItemList,
    criteriaItemId: state.tallenesTale.criteriaItemId,
    excelImportState: state.tallenesTale.excelImportState,
    userJWT: state.auth.userJWT,
    currentBreakpoint: state.ui.currentBreakpoint,
    isLoadingComponent: state.utils.isLoading,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    setIsloading: (val: boolean) => dispatch(actions.setIsloading(val)),
    setExcelImportState: (val: any) => dispatch(actions.setExcelImportState(val)),
    excelPost: (val: any) => dispatch(actions.excelPost(val)),
    getTaleList: () => dispatch(actions.criteriaItemListGet()),
    criteriaItemListExportToExcel: () => dispatch(actions.criteriaItemListExportToExcel()),
    setCriteriaEditState: (val: number) => dispatch(actions.setCriteriaEditState(val)),
    setCriteriaDeletionState: (val: number) => dispatch(actions.setCriteriaDeletionState(val)),
    setCriteriaRegistrationState: (val: number) => dispatch(actions.setCriteriaRegistrationState(val)),
    setCriteriaItemSelectedList: (selected: number[]) => dispatch(actions.setCriteriaItemSelectedList(selected)),
    setSandwichButtonStateClicked: (val: boolean) => dispatch(actions.setSandwichButtonStateClicked(val)),
    setCriteriaItemId: (val: number) => dispatch(actions.setCriteriaItemId(val)),

  }
}

/********************************************************************************/

class CriteriaItemList extends Component<Props> {
  //
  state = {
    order: "asc",
    orderBy: "id",
    page: 0,
    rowsPerPage: -1, //10
  };

  //Init
  componentDidMount() {
    document.body.removeEventListener('touchmove', Utils.preventDefault);
    if (!this.props.isLoadingComponent) {
      this.props.setIsloading(true);
      this.props.getTaleList();
    }
    this.props.setSandwichButtonStateClicked(false);
    if (this.props.selectedCriteriaItemList?.length !== 0) this.props.setIsloading(false);
  }


  //
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ order, orderBy });
  };
  //
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.setCriteriaItemSelectedList(this.props.criteriaItemList.map(n => n.id));
      return;
    }
    this.props.setCriteriaItemSelectedList([]);
  };
  //
  handleCheckboxClick = (event, id: number) => {
    event.stopPropagation();
    const newSelectedList = Object.assign([], this.props.selectedCriteriaItemList);
    if (newSelectedList.includes(id)) {
      this.props.setCriteriaItemSelectedList(newSelectedList.filter(obj => obj !== id));
    } else {
      newSelectedList.push(id);
      this.props.setCriteriaItemSelectedList(newSelectedList);
    }
  };

  //
  handleSingleDelete = (event, id: number) => {
    event.stopPropagation();
    const newSelectedList: number[] = [];
    newSelectedList.push(id)
    this.props.setCriteriaItemSelectedList(newSelectedList);
    this.props.setCriteriaDeletionState(1);
  };
  //

  handleCriteriaEdit = (event, val: number) => {
    event.stopPropagation(); //
    this.props.setCriteriaEditState(1);
    this.props.setCriteriaItemId(val)
  };
  //
  handleCriteriaSingleDeletion = (event) => {
    event.stopPropagation();
    this.props.setCriteriaDeletionState(1);
  };
  //
  isSelected = (id: number) => {
    if (this.props.selectedCriteriaItemList?.length === 0)
      return false;
    else {
      return this.props.selectedCriteriaItemList?.includes(id)
    }
  }

  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.criteriaItemList.length - page * rowsPerPage);
    //
    return (
      <div className={classes.root} >
        <Paper>
          <CriteriaListTableToolbar />
          <TableContainer className={classes.container}>
            <div className={classes.tableWrapper}>
              <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle">
                <CriteriaListTableHead
                  numSelected={this.props.selectedCriteriaItemList?.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.props.criteriaItemList.length}
                />
                <TableBody>
                  {this.props.criteriaItemList ? Utils.stableSort(this.props.criteriaItemList, Utils.getSorting(order, orderBy))
                    .map((n: TaleCriteriaItem) => {
                      const isSelected = this.isSelected(n.id);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell padding="none">
                            <Checkbox onClick={event => this.handleCheckboxClick(event, n.id)} className="selectCheckbox" checked={isSelected} />
                          </TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.varegruppe + ' - ' + n.navn}</TableCell>
                          {/*Utils.isDesktop ? <>
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.produktsjef}</TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.innkjop}</TableCell></> : null*/}
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.hovedgruppe}</TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.gruppe}</TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.summeringsgruppe}</TableCell>
                          {/*<TableCell className={classes.tableCell} component="th" scope="row" padding="none">{n.totalsummering}</TableCell>*/}
                          <TableCell className={classes.tableCell} component="th" scope="row" padding="none">
                            {this.props.selectedCriteriaItemList?.length === 0 ? <div className={classes.iconWrapper}>
                              <div className={classes.locallinkA} onClick={event => this.handleCriteriaEdit(event, n.id)}>
                                <SVGIconEdit title="Edit" width={18} />
                              </div>
                              <div className={classes.locallinkB} onClick={event => this.handleSingleDelete(event, n.id)}>
                                <SVGIconBin title="Delete" width={18} />
                              </div>
                            </div> : <div className={classes.emptyIcon}>&nbsp;</div>}
                          </TableCell>
                        </TableRow>
                      );
                    }) : null}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={10} /></TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <Toolbar>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Button className={classes.buttonB}
                variant="contained" style={{ width: 230 }} onClick={() => this.props.criteriaItemListExportToExcel()}>BACKUP/EKSPORT TIL EXCEL</Button>
            </div>
            <div className={classes.actions}>
              {/*this.props.criteriaItemList?.length === 0 ? < : null*/}
              <Button className={classes.buttonB} variant="contained" style={{ width: 210 }}
                onClick={() => window.open(Utils.HOMEPAGE_CONTEXT + '/files/tallenes_tale_malen_798.xlsx')}>LAST NED MALEN *.XLSX</Button>
            </div> <div className={classes.actions}>
              <Button className={classes.buttonB} variant="contained" style={{ width: 190 }} onClick={() => this.props.setExcelImportState(1)}>IMPORTERE FRA EXCEL</Button>
            </div>
          </Toolbar>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: { margin: 20, /* border: '3px solid green'*/ },
  container: { height: `calc(98vh - ${165}px)`/* border: '3px solid red'*/ },
  table: { minWidth: 700, fontSize: 200, },
  tableCell: { paddingTop: 0, height: 24 },
  visuallyHidden: {
    border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0,
    position: 'absolute', top: 20, width: 1,
  },
  emptyIcon: { height: 24 },
  locallinkA: { cursor: 'pointer', paddingLeft: 10 },
  locallinkB: { cursor: 'pointer', paddingLeft: 14, },
  buttonB: { width: 200, marginRight: theme.spacing(1) },
  spacer: { flex: "1 1 100%" },
  actions: { color: theme.palette.text.secondary },

  //Samsung GalaxyTab
  '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    container: { height: `calc(90vh - ${213}px)` },
  },
  iconWrapper: { display: 'flex' },
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(CriteriaItemList));


