import { CssBaseline } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SizeMe } from 'react-sizeme';
import Utils from '../../../felles/Utils';
import ShiftHome from '../../../components/ShiftHome';
//import MenuEl from '../../model/MenuEl';
import MenuItemTarget from '../../../models/MenuItemTarget';
import UISize from '../../../models/UISize';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import LPDrawer from '../../../components/LPDrawer';
import CriteriaExcelExportForm from './components/CriteriaExcelExportForm';
import CriteriaItemAdd from './components/CriteriaItemAdd';
import CriteriaItemDelete from './components/CriteriaItemDelete';
import CriteriaItemEdit from './components/CriteriaItemEdit';
import CriteriaItemList from './components/CriteriaItemList';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: { display: 'flex', height: '100vh', width: '100vw' },
    content: {
      marginTop: 15,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -340,
    },
    contentShift: {
      marginTop: 15,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);


export default function CriteriaItemPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  //const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
  const navigate = useHistory();
  const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  const criteriaItemEditState = useSelector((state) => (state as AppState).tallenesTale.criteriaItemEditState);
  const criteriaItemDeletionState = useSelector((state) => (state as AppState).tallenesTale.criteriaItemDeletionState);
  const criteriaItemRegistrationState = useSelector((state) => (state as AppState).tallenesTale.criteriaItemRegistrationState);
  const criteriaItemId = useSelector((state) => (state as AppState).tallenesTale.criteriaItemId);
  const excelImportState = useSelector((state) => (state as AppState).tallenesTale.excelImportState);

  //
  const handlePressedKey = (event) => {
    const { key } = event;
    if ((event.shiftKey && key === 'End') || key === 'F3') {
      dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.tilpassetBeta, n.kodeSynlig, n.labelSynlig, n.hasChildren, n.title)));
      dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));
      navigate.push(Utils.HOMEPAGE_CONTEXT + '/menu');
    }
  }

  //
  const generateEditStates = () => {
    switch (true) {
      case (criteriaItemEditState === 0):
        return null;
      default:
        return <CriteriaItemEdit id={criteriaItemId} />;
    }
  }

  //
  const generateRegistrationStates = () => {
    switch (true) {
      case (criteriaItemRegistrationState === 0):
        return null;
      case (criteriaItemRegistrationState === 1):
        return <CriteriaItemAdd />;
      case (criteriaItemRegistrationState === 2):
        return <CriteriaItemAdd />;
      case (criteriaItemRegistrationState === 3):
        return <CriteriaItemAdd />;
      default:
        return null;
    }
  }

  //
  const generateExcelImportStates = () => {
    switch (true) {
      case (excelImportState === 0):
        return null;
      case (excelImportState === 1):
        return <CriteriaExcelExportForm />;
      case (excelImportState === 2):
        return <CriteriaExcelExportForm />;
      case (excelImportState === 3):
        return <CriteriaExcelExportForm />;
      case (excelImportState === 4):
        return <CriteriaExcelExportForm />;
      default:
        return null;
    }
  }


  // REWRITE
  const generateDeletionStates = () => {
    switch (true) {
      case (criteriaItemDeletionState === 0):
        return null;
      default:
        return <CriteriaItemDelete />;
    }
  }

  const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);

  return (
    <div className={classes.root} tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
      <ShiftHome />
      <CssBaseline />
      <LPDrawer />
      <main className={clsx(classes.content, { [classes.contentShift]: button ? true : open })}>
        <SizeMe>{({ size }) => <CriteriaItemList size={size} />}</SizeMe>
      </main>
      {generateEditStates()}
      {generateRegistrationStates()}
      {generateDeletionStates()}
      {generateExcelImportStates()}
    </div>
  );
}