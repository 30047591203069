import { Backdrop } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVitec from '../../../../components/ButtonVitec';
import DialogVitec from '../../../../components/DialogVitec';
import TaleCriteriaItem from '../../../../models/TaleCriteriaItem';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      // I used the rgba color for the standard "secondary" color
      backgroundColor: '#fff',
      fontSize: '1em',
      color: 'black',
      border: '1px solid #fff',
      margin: 0,
      marginTop: 10,
      marginLeft: -5,
    },
    customArrow: { color: ' #fff', },
    root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
    formWrapper: { width: '100vw', display: 'table-cell', verticalAlign: 'middle', height: '93vh', padding: 20, },
    formAdd: {
      marginTop: 'min(20%)',
      marginBottom: 'min(20%)',
      boxShadow: '3px 3px 3px #222',
      horizontalAlign: 'center',
      backgroundColor: '#f3f3f3',
      width: 630,
      margin: '0 auto',
      padding: 30,
      border: '1px solid #333',
      borderRadius: '5px',
    },
    mInt: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
    myLabel: { width: 600, fontSize: '1.2em' },
    myTitle: { width: 600, fontSize: '1.6em', marginBottom: '30px', color: 'black' },
    myContent: { width: 550, fontSize: '1em', marginBottom: '10px', color: 'black' },
    formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
    checkboxWrapper: { color: '#000' },
    checkbox: { display: 'inline-flex', marginLeft: 10, marginBottom: 10 },
    grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
    myButtons: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', },
    dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
    myDialogTitle: { textAlign: 'center', fontSize: '1.4em', width: '100%', },
    rowField: { display: 'flex', },

    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
      formWrapper: { padding: 5 },
      formAdd: { width: 700, height: 500, padding: 15 },
      mInt: { width: '100%', padding: '5px', marginBottom: '7px', marginTop: '0px', border: '1px solid #ccc' },
      myLabel: { width: 500, fontSize: '1em' },
      myTitle: { width: 500, fontSize: '1.2em', marginBottom: '10px', color: 'black' },
      myContent: { width: 450, fontSize: '1em', marginBottom: '10px', color: 'black' },
      formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
      backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
      checkboxWrapper: { color: '#000' },
      checkbox: { display: 'inline-flex', marginLeft: 5, marginBottom: 5 },
      grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
      myButtons: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-295px)' },
      dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
    },




  }),
);

const CriteriaItemDelete = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const criteriaItemDeletionState: number = useSelector((state) => (state as AppState).tallenesTale.criteriaItemDeletionState);
  //const isDeletingCriteriaItem: boolean = useSelector((state) => (state as AppState).tallenesTale.isDeletingCriteriaItem);
  const error: any = useSelector((state) => (state as AppState).tallenesTale.error);
  const selectedCriteriaItemList: number[] = useSelector((state) => (state as AppState).tallenesTale.selectedCriteriaItemList);
  const formRef: any = createRef();
  const taleCriteriaItem: TaleCriteriaItem[] = useSelector((state) => (state as AppState).tallenesTale.criteriaItemList);   // let item1 = taleCriteriaItem.find(i => i.id === 1);

  useEffect(() => {
    //console.log(' ---3-- error ' , error?.response?.data);
  }, [dispatch, selectedCriteriaItemList, criteriaItemDeletionState, error]);

  const onSubmit = (event: Event) => {
    event.preventDefault();
    dispatch(actions.criteriaItemListSelectedDeletion(selectedCriteriaItemList));
  };

  //
  const onCancel = () => {
    dispatch(actions.setCriteriaDeletionState(0));
    dispatch(actions.setCriteriaItemSelectedList([]));
    // Som clean up
  };

  //
  const handleDiagValidateUserSaved = () => {
    dispatch(actions.setCriteriaDeletionState(0));
    // dispatch(actions.criteriaItemListSelectedDeletion([]));
    dispatch(actions.setCriteriaItemSelectedList([]));
    dispatch(actions.criteriaItemListGet());
  };

  //
  const getList = () => {
    const taleCriteriaItemToDelete: any = [];
    selectedCriteriaItemList.forEach((item) => taleCriteriaItem.find(i => {
      if (i.id === item) taleCriteriaItemToDelete.push(i.navn + ' (' + i.varegruppe + ') ')
    }))
    return taleCriteriaItemToDelete;
  };
  //


  //
  const generateCriteriaEditionStates = () => {
    switch (true) {
      case criteriaItemDeletionState === 1:
        return (
          <>
            <form ref={formRef} className={classes.formAdd}>
              <div className={classes.myTitle}>Er du sikker på at du vil slette: </div>
              <div className={classes.myContent}><> {getList()}?</></div>
              <div className={classes.myButtons}>
                <ButtonVitec _onclick={onSubmit} _width={180} _height={37} _type={'submit'} _label={'SLETT VAREGRUPPE'} />
                <ButtonVitec _onclick={() => onCancel()} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
              </div>
            </form>
          </>
        );
      case criteriaItemDeletionState === 2:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Informasjon slettet</div>
            <div className={classes.myButtons}>
              <ButtonVitec _onclick={handleDiagValidateUserSaved} _width={80} _height={37} _type={'button'} _label={'OK'} />
            </div>
          </DialogVitec>
        );
      case criteriaItemDeletionState === 3:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>'Informasjon er ikke slettet'</div>{error ? error + '' : 'd'}
            <div className={classes.myButtons}>
              <ButtonVitec _onclick={handleDiagValidateUserSaved} _width={80} _height={37} _type={'button'} _label={'Ok'} />
            </div>
          </DialogVitec>
        );
      default:
        return null;
    }
  };
  //
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>
        {generateCriteriaEditionStates()}
      </Backdrop>
    </div>
  );
};

export default CriteriaItemDelete;
